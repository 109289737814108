// React
import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Custom Components
import ResourcesDropdown from "./ResourcesDropdown";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Context
import { useDataContext } from "../Providers/DataLoader";

// Exports
function UpdateDialog(props) {
  const { resources } = useDataContext();
  const { user, setState } = props;
  const {
    setName,
    setEmail,
    setSkeduloName,
    setAdmin,
    setSuspended,
  } = setState;
  const { block, smallMarginBottom, noMargin } = useStyles();
  const inputClasses = [block, smallMarginBottom].join(" ");
  return (
    <React.Fragment>
      <TextField
        defaultValue={user.name}
        label="Name"
        className={inputClasses}
        fullWidth
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        defaultValue={user.email}
        label="Email"
        className={inputClasses}
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
      />
      <ResourcesDropdown
        className={inputClasses}
        resources={resources}
        defaultValue={user.skedulo_resource_name}
        onChange={(e) => {
          setSkeduloName(e.target.value);
        }}
      />
      <FormControlLabel
        control={<Checkbox defaultChecked={user.admin} />}
        className={noMargin}
        label="Admin"
        labelPlacement="start"
        onChange={(e) => setAdmin(e.target.checked)}
      />
      <FormControlLabel
        control={<Checkbox defaultChecked={user.suspended} />}
        className={noMargin}
        label="Suspended"
        labelPlacement="start"
        onChange={(e) => setSuspended(e.target.checked)}
      />
    </React.Fragment>
  );
}

export default function openUpdateDialog(props) {
  const { users, id, dialog, setState } = props;
  const {
    setName,
    setEmail,
    setSkeduloName,
    setAdmin,
    setSuspended,
    triggerUpdateUser,
  } = setState;

  const user = users.find((user) => user.id == id);
  setName(user.name);
  setEmail(user.email);
  setSkeduloName(user.skedulo_resource_name);
  setAdmin(user.admin);
  setSuspended(user.suspended);

  const buttons = [{ name: "Update", onClick: () => triggerUpdateUser(user) }];
  dialog(
    "Update User",
    <UpdateDialog user={user} setState={setState} />,
    buttons
  );
}
