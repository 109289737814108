// React
import React, { createContext, useContext } from "react";

// Exports
const TabContext = createContext();
export function useTabContext() {
  return useContext(TabContext);
}

export default function TabContextProvider(props) {
  const { tab, setTab, children } = props;
  const tabContext = { tab: tab, setTab: setTab };
  return (
    <TabContext.Provider value={tabContext}>{children}</TabContext.Provider>
  );
}
