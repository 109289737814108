// React
import React, { useState, useEffect } from "react";

// Material UI
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@material-ui/data-grid";

// Custom Components
import openUpdateDialog from "./UpdateUser";
import openNewUserDialog from "./NewUser";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Context
import { useDataContext } from "../Providers/DataLoader";
import { useDialogContext } from "../Providers/DialogSystem";
import { request } from "../../utilities/httpVerb";

// Exports
export default function AdminPage() {
    const { users, refetchUsers, resources } = useDataContext();
    const { dialog } = useDialogContext();
    const { adminContainer, usersTable, rightAligner } = useStyles();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [skeduloName, setSkeduloName] = useState("");
    const [admin, setAdmin] = useState(false);
    const [suspended, setSuspended] = useState(false);
    const [updateUser, setUpdateUser] = useState({ process: false });
    const [createUser, setCreateUser] = useState(false);
    const [usersFiltered, setUsersFiltered] = useState(users);

    const newUser = {
        name: name,
        email: email,
        skedulo_resource_name: skeduloName,
        admin: admin,
        suspended: suspended,
    };

    const triggerUpdateUser = (originalUser) => {
        setUpdateUser({
            process: true,
            originalUser: originalUser,
        });
    };

    const triggerNewUser = () => {
        setCreateUser(true);
    };

    const setState = {
        setName: setName,
        setEmail: setEmail,
        setSkeduloName: setSkeduloName,
        setAdmin: setAdmin,
        setSuspended: setSuspended,
        triggerUpdateUser: triggerUpdateUser,
        triggerNewUser: triggerNewUser,
    };

    const getColumns = (props) => {
        const { users, dialog } = props;
        return [
            { field: "name", headerName: "Name", width: 200 },
            { field: "email", headerName: "Email", width: 150 },
            {
                field: "skedulo_resource_name",
                headerName: "Sked Crew Name",
                width: 200,
            },
            {
                field: "admin",
                headerName: "Admin",
                width: 100,
                renderCell: (params) => (
                    <Checkbox checked={params.value} disabled />
                ),
            },
            {
                field: "suspended",
                headerName: "Suspended",
                width: 150,
                renderCell: (params) => (
                    <Checkbox checked={params.value} disabled />
                ),
            },
            {
                field: "id",
                headerName: "Edit",
                renderCell: (params) => (
                    <IconButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                            openUpdateDialog({
                                users: users,
                                id: params.value,
                                dialog: dialog,
                                setState: setState,
                            })
                        }
                    >
                        <EditIcon />
                    </IconButton>
                ),
            },
        ];
    };

    const columns = getColumns({
        users: users,
        dialog: dialog,
    });

    function processUpdateUser() {
        if (updateUser.process) {
            const data = {
                email: updateUser.originalUser.email,
                user: newUser,
            };
            request("/registrations/update", data, "POST").then(() => {
                setUpdateUser({ process: false });
                refetchUsers();
            });
        }
    }

    function processNewUser() {
        if (newUser) {
            const data = {
                user: newUser,
            };
            request("/registrations/create", data, "POST").then(() => {
                setCreateUser(false);
                refetchUsers();
            });
        }
    }

    useEffect(processUpdateUser, [updateUser.process]);
    useEffect(processNewUser, [createUser]);

    return (
        <Container maxWidth="md" className={adminContainer}>
            <div className={rightAligner}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        openNewUserDialog({
                            dialog: dialog,
                            setState: setState,
                        })
                    }
                >
                    New User
                </Button>
            </div>
            <Paper elevation={5} className={usersTable}>
                <SearchBar users={users} setUsersFiltered={setUsersFiltered} />
                <DataGrid
                    rows={usersFiltered}
                    columns={columns}
                    pageSize={10}
                />
            </Paper>
        </Container>
    );
}

function SearchBar(props) {
    const { users, setUsersFiltered } = props;
    const [search, setSearch] = useState("");

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleFilter = () => {
        const filteredUsers = users.filter((user) => {
            return (
                user.name.toLowerCase().includes(search.toLowerCase()) ||
                user.email.toLowerCase().includes(search.toLowerCase()) ||
                user.skedulo_resource_name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        });
        setUsersFiltered(filteredUsers);
    };

    useEffect(handleFilter, [search]);

    return (
        <Box style={{ width: "100%", padding: "16px" }}>
            <TextField
                style={{ minWidth: "400px" }}
                id="search-bar"
                label="Search"
                variant="outlined"
                placeholder="Search by name, email, or Skedulo resource name"
                onChange={handleSearch}
                startIcon={<SearchIcon />}
            />
        </Box>
    );
}
