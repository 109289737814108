// React
import React, { memo } from "react";

// Material UI
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Exports
function ResourcesDropdown(props) {
  const { resources, defaultValue, onChange, className } = props;
  const {fullWidth} = useStyles()
  return (
    <FormControl fullWidth className={className}>
      <InputLabel>Skedulo Resource Name</InputLabel>
      <Select onChange={onChange} defaultValue={defaultValue} className={fullWidth}>
        {resources.map((resource) => (
          <MenuItem key={resource.node.UID} value={resource.node.Name}>
            {resource.node.Name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default memo(ResourcesDropdown);
