// React
import React from "react";

// Components
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

// Exports
export default function MuiTheme({ children }) {
  const themeObject = {
    overrides: {
      MuiButton: {
        root: {
          color: "white",
          textTransform: "none",
          fontWeight: 500,
          color: "white",
        },
      },
      MuiIconButton: { root: { color: "white" } },
    },
  };
  const theme = createMuiTheme(themeObject);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
