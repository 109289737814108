// React
import React, { createContext, useContext, useState, useEffect } from "react";

// Custom Components
import Loader from "./Loader";

// Utilities
import { request } from "../../utilities/httpVerb";

// Context
import { useFilterContext } from "./Filter";
import { useSessionContext } from "./Session";

// Exports
const DataContext = createContext();
export function useDataContext() {
  return useContext(DataContext);
}

export default function DataContextProvider(props) {
  const { children } = props;
  const { session } = useSessionContext();
  const { authenticated, user } = session;
  const filter = useFilterContext();

  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([])
  const [resources, setResources] = useState([])
  const [loaded, setLoaded] = useState(false);

  const [userCounter, setUserCounter] = useState(0);

  const refetchUsers = () => {
    setUserCounter(userCounter + 1);
  };
  const refetchJobs = () => {
    setJobsCounter(jobsCounter + 1);
  };

  useEffect(() => {
    if (authenticated) {
      request("/jobs", filter, "POST")
        .then((res) => {
          try {
            setJobs(res);
          } catch (err) {
            console.error(err);
          }
        })
        .then(() => {
          if (user.admin) {
            fetch("/sessions/index")
              .then((res) => res.json())
              .then((users) => {
                setUsers(users);
              })
              .then(() => {
                fetch("/resources")
                  .then((res) => res.json())
                  .then((resources) => {
                    setResources(resources);
                    setLoaded(true);
                  });
              });
          } else {
            setLoaded(true);
          }
        });
    } else {
      setLoaded(true);
    }
  }, [userCounter]);

  const dataContext = {
    jobs: jobs,
    setJobs: setJobs,
    refetchJobs: refetchJobs,
    users: users,
    setUsers: setUsers,
    refetchUsers: refetchUsers,
    resources: resources,
    loaded: loaded,
  };

  if (loaded) {
    return (
      <DataContext.Provider value={dataContext}>
        {children}
      </DataContext.Provider>
    );
  } else {
    return <Loader />;
  }
}
