// React
import React, { createContext, useContext, useState } from "react";

// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Exports
const DialogContext = createContext();
export function useDialogContext() {
  const dialogContext = useContext(DialogContext);
  return dialogContext;
}

export function DialogContextProvider(props) {
  const { children } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [DialogComponent, setDialogComponent] = useState(null);
  const [dialogButtons, setDialogButtons] = useState([]);
  const [dialogStyle, setDialogStyle] = useState({});
  const dialog = (title, component, buttons, style) => {
    setDialogTitle(title);
    setDialogComponent(component);
    setDialogButtons(buttons);
    setDialogOpen(true);
    setDialogStyle(style);
  };

  const dialogContext = {
    dialogOpen: dialogOpen,
    dialogTitle: dialogTitle,
    DialogComponent: DialogComponent,
    dialogButtons: dialogButtons,
    dialogStyle: dialogStyle,
    setDialogOpen: setDialogOpen,
    setDialogTitle: setDialogTitle,
    setDialogComponent: setDialogComponent,
    setDialogButtons: setDialogButtons,
    setDialogStyle: setDialogStyle,
    dialog: dialog,
  };

  return (
    <DialogContext.Provider value={dialogContext}>
      {children}
    </DialogContext.Provider>
  );
}
export default function DialogSystem() {
  const {
    dialogOpen,
    dialogTitle,
    DialogComponent,
    dialogButtons,
    dialogStyle,
    setDialogOpen,
    setDialogTitle,
    setDialogComponent,
    setDialogButtons,
    setDialogStyle,
  } = useDialogContext();

  const ContentComponent = () => {
    return DialogComponent;
  };

  const handleClose = () => {
    setDialogOpen(false);
    setDialogTitle("");
    setDialogComponent(null);
    setDialogButtons([]);
    setDialogStyle({});
  };

  const buttonStyle = {
    height: "100%",
    marginLeft: "2%",
    marginRight: "2%",
  };

  const { dialogPaper } = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      open={dialogOpen}
      maxWidth="lg"
      classes={{ paper: dialogPaper }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <ContentComponent />
      </DialogContent>
      <DialogActions>
        {[
          ...dialogButtons.map((b) => {
            return (
              <Button
                onClick={() => {
                  handleClose();
                  b.onClick();
                }}
                color="primary"
                variant="contained"
                style={buttonStyle}
                key={b.name + " Button"}
              >
                {b.name}
              </Button>
            );
          }),
        ]}
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          style={buttonStyle}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
