// React
import React, { createContext, useContext, useEffect, useState } from "react";

// Custom Components
import Loader from "./Loader";

// Exports
const SessionContext = createContext();
export const useSessionContext = () => useContext(SessionContext);

export default function SessionProvider({ children }) {
  const [session, setSession] = useState(null);
  const [loaded, setLoaded] = useState(false);

  function fetchSession() {
    fetch("sessions/show")
      .then((session) => session.json())
      .then((session) => {
        setSession(session);
        setLoaded(true);
      });
  }

  useEffect(fetchSession, []);
  const sessionContext = { session: session, setSession: setSession };
  if (loaded) {
    return (
      <SessionContext.Provider value={sessionContext}>
        {children}
      </SessionContext.Provider>
    );
  } else {
    return <Loader />;
  }
}