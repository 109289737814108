// React
import React, { createContext, useContext, useState } from "react";

// Context
import { useSessionContext } from "./Session";

// Utilities
import { getMonday, addDays } from "../../utilities/dates";

// Exports
const FilterContext = createContext();
export function useFilterContext() {
  return useContext(FilterContext);
}

export default function FilterContextProvider(props) {
  const { children } = props;
  const {session} = useSessionContext()
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(getMonday(new Date()));

  const changeWeek = (value) => {
    const dayChange = value * 7;
    const date = new Date(startDate);
    const newDate = date.getDate() + dayChange;
    date.setDate(newDate);
    setStartDate(date);
    setDate(date);
  };

  const changeDate = (value) => {
    setDate(value);
  };

  const arr = [1, 2, 3, 4, 5, 6];
  const weekDays = [startDate];
  arr.map((i) => {
    weekDays.push(addDays(startDate, i));
  });

  // Get this default from the session
  const resourceName = session.user.skedulo_resource_name;

  const filterContext = {
    resourceName: resourceName,
    startDate: startDate,
    weekDays: weekDays,
    date: date,
    changeDate: changeDate,
    changeWeek: changeWeek,
  };

  return (
    <FilterContext.Provider value={filterContext}>
      {children}
    </FilterContext.Provider>
  );
}