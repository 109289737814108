// React
import React from "react";

// Custom Component
import JobCard from "./JobCard";

// Material UI
import Container from "@material-ui/core/Container";

// Context
import { useDataContext } from "../Providers/DataLoader";
import { useFilterContext } from "../Providers/Filter";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Utility
import { formatDate } from "../../utilities/dates";

// Exports
export default function Feed() {
  const { jobs, loaded } = useDataContext();
  const { date } = useFilterContext();
  const { feedHeight, overflowYAuto } = useStyles();
  const todayJobs = jobs.filter(
    (job) => job.Job.StartDateInZone == formatDate(date)
  );

  return (
    <Container
      className={[feedHeight, overflowYAuto].join(" ")}
      maxWidth="md"
    >
      <Jobs jobs={todayJobs} />
    </Container>
  );
}

const Jobs = (props) => {
  const { jobs } = props;
  return [...jobs.map((job) => <JobCard job={job} key={job.Job.UID} />)];
};
