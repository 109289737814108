// React
import React from "react";
import ReactDOM from "react-dom";

// Importing App
import App from "../components/App";

// Mounting App
const container = document.createElement("div");
container.setAttribute(
  "style",
  "height:100%; width: 100%; background-color: #f0f0f0"
);
document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<App />, document.body.appendChild(container));
});
