// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Exports
const appBarHeight = "200px";
const toolsHeight = "70px";
export const useStyles = makeStyles((theme) => ({
  appBar: { height: appBarHeight },
  tools: { height: `${toolsHeight} !important` },
  monthBar: {
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
  },
  daysBar: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    height: `calc(${appBarHeight} - "60px")`,
  },
  adminContainer: {
    height: `calc(100% - ${toolsHeight})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  feedHeight: {
    height: `calc(100% - ${appBarHeight} - ${toolsHeight})`,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  center: { display: "flex", justifyContent: "center" },
  buttonBox: {
    height: "100%",
    minWidth: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  typographyButton: {
    minWidth: "50px",
  },
  banner: {
    width: "100%",
    height: "20%",
    minHeight: "80px",
  },
  login: {
    minHeight: "200px",
  },
  activeButton: {
    backgroundColor: "white !important",
    color: `${theme.palette.primary.main} !important`,
    "&:hover": {
      backgroundColor: "white !important",
      color: `${theme.palette.primary.main} !important`,
    },
  },
  smallMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  smallMargin: {
    margin: theme.spacing(2),
  },
  smallMarginVertical: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  largeMarginVertical: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  largeMarginTop: {
    marginTop: theme.spacing(10),
  },
  largeMargin: { margin: theme.spacing(10) },
  noMargin: {
    marginTop: "0px !important",
    marginBottom: "0px !important",
    marginLeft: "0px !important",
    marginRight: "0px !important",
  },
  staticPosition: { position: "static !important" },
  twentyHeight: { height: "20%" },
  thirtyHeight: { height: "30%" },
  eightyHeight: { height: "80%" },
  eightyWidth: { width: "80%" },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: { width: "50%" },
  overflowYAuto: { overflowY: "auto" },
  noDecoration: { textDecoration: "none" },
  block: { display: "block" },
  verticalColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cardContent: {
    padding: theme.spacing(3),
    height: `calc(70% - ${theme.spacing(3) * 2}px)`,
  },
  cardActions: {
    padding: theme.spacing(3),
    height: `calc(30% - ${theme.spacing(3) * 2}px)`,
  },
  zIndex2: { zIndex: "2 !important" },
  errorMessage: { color: "red" },
  usersTable: {
    height: "80%",
    width: "100%",
    marginTop: theme.spacing(2)
  },
  dialogPaper: {
    minWidth: "500px",
  },
  rightAligner: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
}));
