// React
import React, { useState } from "react";

// Material UI
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ScheduleIcon from "@material-ui/icons/CalendarToday";
import AdminIcon from "@material-ui/icons/Settings";

// Context
import { useSessionContext } from "../Providers/Session";
import { useDataContext } from "../Providers/DataLoader";
import { useTabContext } from "../Providers/Tabs";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Utilities
import { request } from "../../utilities/httpVerb";

// Exports
export default function Tools() {
  const { session, setSession } = useSessionContext();
  const { setJobs, setUsers } = useDataContext();
  const { tab, setTab } = useTabContext();
  const { tools } = useStyles();

  const handleLogout = () => {
    const logout = {
      email: session.user.email,
    };
    request("/users/sign_out", logout, "DELETE").then(() => {
      setSession({ authenticated: false });
      setJobs([]);
      setUsers([])
      setTab(0)
    });
  };

  return (
    <BottomNavigation
      value={tab}
      onChange={(e, newValue) => {
        setTab(newValue);
      }}
      showLabels
      className={tools}
    >
      <BottomNavigationAction label="Schedule" icon={<ScheduleIcon />} />
      <BottomNavigationAction
        label="Logout"
        icon={<LogoutIcon />}
        onClick={handleLogout}
      />
      {session.user.admin ? (
        <BottomNavigationAction label="Admin" icon={<AdminIcon />} />
      ) : null}
    </BottomNavigation>
  );
}
