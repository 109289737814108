// React
import React from "react";

// Material UI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import LeftArrow from "@material-ui/icons/ArrowLeft";
import RightArrow from "@material-ui/icons/ArrowRight";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Context
import { useFilterContext } from "../Providers/Filter";
import { useDataContext } from "../Providers/DataLoader";

// Utilities
import { formatDate } from "../../utilities/dates";

// Exports
export default function Navigation() {
  const { startDate, changeWeek } = useFilterContext();
  const { daysBar, monthBar, staticPosition, appBar } = useStyles();
  const month = startDate.toLocaleDateString("en-us", {
    month: "long",
    year: "numeric",
  });
  return (
    <AppBar className={[staticPosition, appBar].join(" ")}>
      <Toolbar className={monthBar}>
        <LeftButton changeWeek={changeWeek} />
        <Month month={month} />
        <RightButton changeWeek={changeWeek} />
      </Toolbar>
      <Toolbar className={[daysBar].join(" ")}>
        <Days startDate={startDate} />
      </Toolbar>
    </AppBar>
  );
}

const LeftButton = ({ changeWeek }) => {
  return (
    <IconButton onClick={() => changeWeek(-1)}>
      <LeftArrow fontSize="large" />
    </IconButton>
  );
};

const RightButton = ({ changeWeek }) => {
  return (
    <IconButton onClick={() => changeWeek(1)}>
      <RightArrow fontSize="large" />
    </IconButton>
  );
};

const Month = ({ month }) => {
  return <Typography variant="h6">{month}</Typography>;
};

const Days = ({ startDate }) => {
  const { date, weekDays, changeDate } = useFilterContext();
  const { jobs } = useDataContext();
  return weekDays.map((day) => (
    <Day
      day={day}
      date={date}
      changeDate={changeDate}
      key={"Day " + day.getDay()}
      jobs={jobs}
    />
  ));
};

const Day = ({ day, date, changeDate, jobs }) => {
  const {
    buttonBox,
    typographyButton,
    activeButton,
    smallMarginBottom,
    smallMarginVertical,
    block,
  } = useStyles();

  const isToday =
    day.toLocaleDateString("en-us") == date.toLocaleDateString("en-us");

  const jobCount = jobs.filter(
    (job) => job.Job.StartDateInZone == formatDate(day)
  ).length;

  const hasJob = jobCount > 0;

  const activeStyle = isToday ? activeButton : "";

  const dayObject = {
    1: "M",
    2: "T",
    3: "W",
    4: "R",
    5: "F",
    6: "S",
    0: "S",
  };

  return (
    <Box className={buttonBox} key={day + "Button"}>
      <Typography align="center" className={smallMarginBottom}>
        {dayObject[day.getDay()]}
      </Typography>
      <IconButton
        className={[typographyButton, activeStyle, block].join(" ")}
        onClick={() => changeDate(day)}
      >
        <Typography>{day.getDate()}</Typography>
      </IconButton>
      <Chip
        label={jobCount}
        className={smallMarginVertical}
        color={"secondary"}
      />
    </Box>
  );
};