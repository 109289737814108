// React
import React from "react";

// Custom Components
import LoginPage from "../LoginPage";

// Context
import { useSessionContext } from "./Session";

// Exports
export default function Authenticator(props) {
  const { children } = props;
  const { session } = useSessionContext();

  if (session.authenticated) {
    return children;
  } else {
    return <LoginPage />;
  }
}
