// React
import React, { useState } from "react";

// Custom Components
import Navigation from "./Schedule/Navigation";
import Feed from "./Schedule/Feed";
import Tools from "./Schedule/Tools";
import AdminPage from "./Admin/AdminPage";

// Styles
import MuiTheme from "../styles/MuiTheme";

// Context
import Authenticator from "./Providers/Authenticator";
import SessionContextProvider from "./Providers/Session";
import FilterContextProvider from "./Providers/Filter";
import DataContextProvider from "./Providers/DataLoader";
import TabContextProvider from "./Providers/Tabs";
import DialogSystem, { DialogContextProvider } from "./Providers/DialogSystem";

// Exports
export default function App() {
  const [tab, setTab] = useState(0);
  return (
    <MuiTheme>
      <SessionContextProvider>
        <Authenticator>
          <FilterContextProvider>
            <DataContextProvider>
              <TabContextProvider tab={tab} setTab={setTab}>
                <DialogContextProvider>
                  <DialogSystem />
                  {tab === 0 ? (
                    <React.Fragment>
                      <Navigation />
                      <Feed />
                    </React.Fragment>
                  ) : null}
                  {tab === 2 ? <AdminPage /> : null}
                  <Tools />
                </DialogContextProvider>
              </TabContextProvider>
            </DataContextProvider>
          </FilterContextProvider>
        </Authenticator>
      </SessionContextProvider>
    </MuiTheme>
  );
}
