export function getMonday(d) {
  var day = d.getDay();
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate() + (day == 0 ? -6 : 1) - day
  );
}

export function getSunday(d) {
  var day = d.getDay();
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate() + (day == 0 ? 0 : 7) - day
  );
}

export function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}

export function formatDate(date) {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
