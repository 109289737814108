// React
import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// Custom Components
import ResourcesDropdown from "./ResourcesDropdown";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Context
import { useDataContext } from "../Providers/DataLoader";

// Exports
function NewUserDialog(props) {
  const { resources } = useDataContext();
  const { setState } = props;
  const {
    setName,
    setEmail,
    setSkeduloName,
    setAdmin,
    setSuspended,
  } = setState;
  const { block, smallMarginBottom, noMargin } = useStyles();
  const inputClasses = [block, smallMarginBottom].join(" ");
  return (
    <React.Fragment>
      <TextField
        defaultValue={""}
        label="Name"
        className={inputClasses}
        fullWidth
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        defaultValue={""}
        label="Email"
        className={inputClasses}
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
      />
      <ResourcesDropdown
        className={inputClasses}
        resources={resources}
        defaultValue={""}
        onChange={(e) => {
          setSkeduloName(e.target.value);
        }}
      />
      <FormControlLabel
        control={<Checkbox defaultChecked={false} />}
        className={noMargin}
        label="Admin"
        labelPlacement="start"
        onChange={(e) => setAdmin(e.target.checked)}
      />
      <FormControlLabel
        control={<Checkbox defaultChecked={false} />}
        className={noMargin}
        label="Suspended"
        labelPlacement="start"
        onChange={(e) => setSuspended(e.target.checked)}
      />
    </React.Fragment>
  );
}

export default function openNewUserDialog(props) {
  const { dialog, setState } = props;
  const {
    setName,
    setEmail,
    setSkeduloName,
    setAdmin,
    setSuspended,
    triggerNewUser,
  } = setState;

  setName("");
  setEmail("");
  setSkeduloName("");
  setAdmin(false);
  setSuspended(false);

  const buttons = [{ name: "Create", onClick: triggerNewUser }];
  dialog("New User", <NewUserDialog setState={setState} />, buttons);
}
