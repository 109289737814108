// React
import React from "react";

// Material UI
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

// Styles
import { useStyles } from "../../styles/MuiStyle";

// Exports
export default function JobCard(props) {
    const { job } = props;
    const { smallMargin, smallMarginVertical, noDecoration } = useStyles();
    console.log("Job: ", job);
    return (
        <Card className={smallMargin} elevation={5}>
            <CardContent>
                <Chip label={job.Job.Type} color="primary" />
                <Typography className={smallMarginVertical}>
                    {job.Job.StartTimeInZone + " - " + job.Job.EndTimeInZone}
                </Typography>
                <Typography variant={"body1"} className={smallMarginVertical}>
                    {job.Job.Description}
                </Typography>
                <Typography variant={"body2"} className={smallMarginVertical}>
                    <a
                        href={"https://maps.google.com/?q=" + job.Job.Address}
                        target="_blank"
                        className={noDecoration}
                    >
                        {job.Job.Address}
                    </a>
                </Typography>
                {!!job.Job.SiteCaptureURL && (
                    <Typography
                        variant={"body2"}
                        className={smallMarginVertical}
                    >
                        <a
                            href={job.Job.SiteCaptureURL}
                            target="_blank"
                            className={noDecoration}
                        >
                            Site Capture Link
                        </a>
                    </Typography>
                )}
                {!!job.Job.SiteCaptureDailyReportURL && (
                    <Typography
                        variant={"body2"}
                        className={smallMarginVertical}
                    >
                        <a
                            href={job.Job.SiteCaptureDailyReportURL}
                            target="_blank"
                            className={noDecoration}
                        >
                            Generate Daily Report
                        </a>
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}
